import React from "react"
import { StaticImage } from "gatsby-plugin-image"

/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/aspect-ratio'),
    ],
  }
  ```
*/

const sections = [
  {
    name: "24 Hour Affordable Transportation",
    description:
      "It was a dark and stormy night when we first came up with the idea to start our own Taxi Cab Company. We wanted it on call for 24-hours; we bought all of the necessary vehicles and computers, and began hiring drivers. It wasn’t long until people realized they could use us at any time of day or night, like if their car broke down, or they were too drunk to drive home. Soon enough we became known as that one company you can always find on demand!",
    imageAlt:
      "White canvas laptop sleeve with gray felt interior, silver zipper, and tan leather zipper pull.",
    getImage: () => {
      return (
        <StaticImage
          className="object-center object-cover"
          src="../images/24-hour.jpg"
          alt="24 Hour Taxi Sign"
        />
      )
    },
  },
  {
    name: "Airport Transfers ",
    description:
      "The first few months of my career as a taxi cab driver in Connecticut were not easy. We had to compete with the other companies that also offered service at Bradley Airport, but when I started there was no one else providing rides to and from the airport. That gave us an edge over our competitors, but not for long. When we first started our taxi cab company most of our customers were insurance workers flying in and out of Bradley International Airport. The work is very demanding so they would often call for cabs on their way home or if they needed transportation back to the office after being stuck in traffic all day. This meant that business was usually great during morning rush hour but would start slowing down around lunch time until it picked up again just after work ended.",
    imageAlt: "Detail of zipper pull with tan leather and silver rivet.",
    getImage: () => {
      return (
        <StaticImage
          className="object-center object-cover"
          src="../images/airport-taxi.jpg"
          alt="Taxi at an airport"
        />
      )
    },
  },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}

export default function Example() {
  return (
    <div className="py-16 px-4 sm:px-6 sm:py-32 lg:px-8 xl:px-0">
      <div className="max-w-3xl mx-auto text-center">
        <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
          Family owned and operated
        </h2>
        <p className="mt-4 text-gray-500">
          I remember the days when my father used to drive a taxi cab for our
          family. It was always so nice to see him come home, after he had just
          dropped off some customers at their destination. I would always ask
          how many people they went with and if all of them were happy. If they
          weren't, he would tell me about it and we would talk about alternative
          routes or destinations that may be better suited to make them happier
          next time around.
          <br />
          <br />
          We originally opened up our company twenty years ago in order to
          provide more jobs for the community, but also because my father wanted
          something different than his previous job as an accountant for another
          firm in Hartford. He loved taking care of other people by transporting
          them places while listening to music.
        </p>
      </div>

      <div className="mt-16 space-y-16">
        {sections.map((section, sectionIdx) => {
          return (
            <div
              key={section.name}
              className="flex flex-col-reverse lg:grid lg:grid-cols-12 lg:gap-x-8 lg:items-center"
            >
              <div
                className={classNames(
                  sectionIdx % 2 === 0
                    ? "lg:col-start-1"
                    : "lg:col-start-8 xl:col-start-9",
                  "mt-6 lg:mt-0 lg:row-start-1 lg:col-span-5 xl:col-span-4"
                )}
              >
                <h3 className="text-xl lg:text-2xl font-bold text-gray-900">
                  {section.name}
                </h3>
                <p className="mt-2 text-gray-500">{section.description}</p>
              </div>
              <div
                className={classNames(
                  sectionIdx % 2 === 0
                    ? "lg:col-start-6 xl:col-start-5"
                    : "lg:col-start-1",
                  "flex-auto lg:row-start-1 lg:col-span-7 xl:col-span-8"
                )}
              >
                <div className="aspect-w-5 aspect-h-2 rounded-lg bg-gray-100 overflow-hidden">
                  {section.getImage()}
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}
