import * as React from "react"
import { graphql } from "gatsby"
import Hero from "../components/Hero"

import Layout from "../components/Layout"
import Seo from "../components/seo"
import AboutUsSections from "../components/AboutUsSections"

import { getImage } from "gatsby-plugin-image"

const About = ({ data }) => {
  const heroImage = getImage(data.fileName)
  return (
    <Layout>
      <Seo title="About" />
      <div className="bg-black">
        <div className="container mx-auto">
          <Hero
            heading="About Us"
            subheading="Hartford Taxi Service"
            description="We take pride in being one of the most reliable and affordable taxi cab services throughout Connecticut. We have been a family-owned company for more than two decades serving all corners of greater Hartford area!"
            heroImage={heroImage}
            imageAlt="Image of a taxi cab at night"
          />
        </div>
      </div>
      <div className="bg-white">
        <div className="container mx-auto">
          <AboutUsSections />
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query AboutPageQuery {
    allSite {
      edges {
        node {
          siteMetadata {
            defaultTitle
          }
        }
      }
    }
    fileName: file(relativePath: { eq: "hartford-nightsky.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 980)
      }
    }
  }
`

export default About
